import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import User from './User';
import Menu from './Menu';
import styles from './Common.module.css';
import { Link } from "react-router-dom";
import { 
    showMenu,
    commonSelector 
} from './commonSlice';
import { logout } from '../user/thunks/logout';


const Page = ({children}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const common = useSelector(commonSelector);

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <Link to="/" className={styles.logo}>{t('Úvodní stránka')}</Link>
                <div className={styles.title}><h1>{t('Klientské prostředí')}</h1></div>
                <User />
                <div className={styles.control}>
                    <button className={styles.logoutButton} onClick={() => dispatch(logout())}>{t("odhlásit se")}</button>
                </div>
                <button className={styles.userButton}>user</button>
                <button className={styles.menuButton} onClick={() => dispatch(showMenu())}>menu</button>
            </div>
            <div className={styles.content}>{children}</div>
            <Menu />
            <div className={styles.address}>
                <b>{t('COMPANY_NAME')}</b>, {t('COMPANY_STREET')}, {t('COMPANY_ZIP')} {t('COMPANY_CITY')}, {t('telefon')}: <a href={['tel:',t('COMPANY_PHONE')].join('')}>{t('COMPANY_PHONE')}</a>, {t('mobil')}: <a href={['tel:',t('COMPANY_MOBILE')].join('')}>{t('COMPANY_MOBILE')}</a>, {t('e-mail')}: <a href={['mailto:',t('COMPANY_MAIL')].join('')}>{t('COMPANY_MAIL')}</a>
            </div>
            <button className={[styles.topButton, common.scroll ? null : styles.invisible].join(' ')} onClick={() => scrollTop()}>top</button>
        </div>
    );
};

export default Page;