import axios from "axios";
import i18next from "i18next";


export const getStateList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/state',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(harmonogram => harmonogram.stateList.map(
        (state) => ({
            id:             Number(state.id),
            title:          String(state.title),
            description:    String(state.description),
            kind:           Number(state.kind),
            expectedLength: Number(state.expectedLength),
            realLength:     Number(state.realLength),
            date:           String(state.date),
            note:           String(state.note),
            stage:          Number(state.stage),
            clima:          Number(state.clima),
            restoreTime:    Number(state.restoreTime),
            addToNextState: Number(state.addToNextState),
            eventList:      state.eventList.map((event) => ({
                id:     Number(event.id),
                from:   String(event.from),
                to:     String(event.to),
                kind:   Number(event.kind),
                note:   String(event.comment)
            }))
        }))
    )
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam stavů')));