import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { programKindEnum } from '../user/enum/programKindEnum';
import { userSelector } from '../user/userSlice';
import styles from './Index.module.css';

const IndexItem = ({item}) => {
    return (
        <li className={item.className}><Link to={item.path}>{item.name}</Link></li>
    );
};

const Index = () => {

    const { t } = useTranslation();
    const user = useSelector(userSelector);

    return (
        <div className={styles.index}>
            <h2>{t("Vítejte ve svém klientském prostředí")}</h2>
            <div className={styles.reference}>
                <Link to="/reference">
                    <h3>{t("odměna za reference")}</h3>
                    <span className={styles.description}>{t("Byli jste s námi spokojeni? Doporučte nás svým známým a my vás odměníme například odpuštěním jedné splátky za váš dům!")}</span>
                </Link>
            </div>
            <ul>
                { [programKindEnum.PROJECT, programKindEnum.CONSTRUCTION].includes(user.user.programKind) && 
                    [
                        {path: "/reference", name: t("odměna za reference"), className: styles.reference},
                        {path: "/harmonogram", name: t("časový harmonogram"), className: styles.harmonogram},
                        {path: "/communication", name: t("zprávy"), className: styles.message},
                        {path: "/file", name: t("soubory"), className: styles.file},
                        {path: "/loan", name: t("půjčky"), className: styles.loan},
                        {path: "/helpline", name: t("helpline řediteli"), className: styles.helpline}
                        // {path: "/profile", name: t("profil"), className: styles.profile}
                    ].map(item => <IndexItem item={item} key={item.path} />)
                }

                { [programKindEnum.WARRANTY_PERIOD].includes(user.user.programKind) && 
                    [
                        {path: "/reference", name: t("odměna za reference"), className: styles.reference},
                        {path: "/loan", name: t("půjčky"), className: styles.loan},
                        // {path: "/profile", name: t("profil"), className: styles.profile},
                        {path: "/service", name: t("servis"), className: styles.service}
                    ].map(item => <IndexItem item={item} key={item.path} />)
                }
            </ul>
        </div>
    );
};

export default Index;