import React from 'react';
import styles from './Harmonogram.module.css';
import { Link } from "react-router-dom";
import { stateKindEnum } from './enum/stateKindEnum';
import { stateStageEnum } from './enum/stateStageEnum';

const HarmonogramState = ({state}) => {

    const stageStyle = ({
        [stateStageEnum.HISTORY]: styles.finished,
        [stateStageEnum.CURRENT]: styles.current,
        [stateStageEnum.FUTURE]:  styles.future
    })[state.stage];
    
    const kindStyle = state.kind === stateKindEnum.COMPANY ? styles.company : styles.client;

    const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(state.date)).replace(/ /g, "");

    const writeDelay = (delay) => (delay > 0 ? ('+' + delay) : delay); 

    return (
        <li className={[kindStyle,stageStyle].join(' ')}><Link className={styles.link} to={"/state/" + state.id}>
            <span className={styles.date}>{date}</span>
            <span className={styles.content}>
                <span className={styles.name}>{state.title}</span>
                <span className={styles.properties}>
                    { state.delay.client.map((delay, index) => (
                        <span key={index} className={[styles.delay,styles.client].join(' ')}>{writeDelay(delay.length)}</span>
                    ))}
                    { state.delay.company.map((delay, index) => (
                        <span key={index} className={[styles.delay,styles.company].join(' ')}>{writeDelay(delay.length)}</span>
                    ))}
                    { state.delay.god.map((delay, index) => (
                        <span key={index} className={[styles.delay,styles.god].join(' ')}>{writeDelay(delay.length)}</span>
                    ))}

                    { state.clima === 1 &&
                        <span className={[styles.icon,styles.clima].join(' ')}></span>
                    }
                    { state.eventList.length > 0 &&
                        <span className={[styles.icon,styles.hurdle].join(' ')}></span>
                    }
                </span></span></Link></li>
    )
};

export default HarmonogramState;