import React from 'react';
import { useSelector } from 'react-redux';
import styles from './File.module.css';
import {
    fileSelector
} from './fileSlice';
import FileFile from './FileFile';


const FileFileList = () => {

    const file = useSelector(fileSelector);

    return (
        <ul className={styles.fileFileList}>{
            file.fileList.map((item) => (<FileFile item={item} key={item.id} />))
        }</ul>
    );
};


export default FileFileList;