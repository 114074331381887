import axios from "axios";
import i18next from "i18next";


export const getMessageList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/message',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(communication => communication.messageList.map(
        (item) => ({
            id:         Number(item.id),
            date:       String(item.date),
            direction:  Number(item.direction),
            title:      String(item.title),
            text:       String(item.text),
            member: {
                id:         Number(item.member.id),
                name:       String(item.member.name),
                position:   Number(item.member.position),
                imageUrl:   String(item.member.imageUrl)
            }
        })
    ))
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam zpráv')));