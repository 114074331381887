import React from 'react';
import { useTranslation } from 'react-i18next';
import HarmonogramNoticeList from './HarmonogramNoticeList';
import HarmonogramStateList from './HarmonogramStateList';
import styles from './Harmonogram.module.css';

const HarmonogramHarmonogram = () => {

    const { t } = useTranslation();

    return (
        <div className={styles.harmonogramHarmonogram}>
            <h2>{t("Harmonogram")}</h2>
            <HarmonogramNoticeList />
            <HarmonogramStateList />
        </div>
    );
};

export default HarmonogramHarmonogram;