import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { programKindEnum } from '../user/enum/programKindEnum';
import { logout } from '../user/thunks/logout';
import { userSelector } from '../user/userSlice';
import styles from './Common.module.css';
import { hideMenu, commonSelector } from './commonSlice';


const MenuItem = ({item}) => {
    
    const location = useLocation();
    const dispatch = useDispatch();

    return (
        <li className={[item.className, location.pathname === item.path ? styles.current : null].join(' ')}>
            <Link className={styles.link} to={item.path} onClick={() => dispatch(hideMenu())}>{item.name}</Link>
        </li>
    );
};

const Menu = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const common = useSelector(commonSelector);
    const user = useSelector(userSelector);

    return (
        <div className={[styles.menu, common.menu ? styles.open : styles.closed].join(' ')}>
            <button className={styles.closeButton} onClick={() => dispatch(hideMenu())}>{t('zavřít')}</button>
            <h3>{t('Klientské prostředí')}</h3>
            <ul>
                { [programKindEnum.PROJECT, programKindEnum.CONSTRUCTION].includes(user.user.programKind) && 
                    [
                        {path: "/reference", name: t("reference"), className: styles.reference},
                        {path: "/harmonogram", name: t("časový harmonogram"), className: styles.harmonogram},
                        {path: "/communication", name: t("zprávy"), className: styles.message},
                        {path: "/helpline", name: t("helpline řediteli"), className: styles.helpline},
                        {path: "/file", name: t("soubory"), className: styles.file},
                        {path: "/loan", name: t("půjčky"), className: styles.loan},
                        // {path: "/profile", name: t("profil"), className: styles.profile}
                    ].map(item => <MenuItem item={item} key={item.path} />)
                }

                { [programKindEnum.WARRANTY_PERIOD].includes(user.user.programKind) && 
                    [
                        {path: "/reference", name: t("reference"), className: styles.reference},
                        {path: "/loan", name: t("půjčky"), className: styles.loan},
                        // {path: "/profile", name: t("profil"), className: styles.profile},
                        {path: "/service", name: t("servis"), className: styles.service}
                    ].map(item => <MenuItem item={item} key={item.path} />)
                }

                <li className={styles.logout}>
                    <Link className={styles.link} onClick={() => dispatch(logout())} to={'#'}>{t("Odhlásit se")}</Link>
                </li>
            </ul>
        </div>
    );
};

export default Menu;