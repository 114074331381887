import { LocalDate } from "../../../lib/localDate";
import { delayKindEnum } from "../enum/delayKindEnum";
import { stateKindEnum } from "../enum/stateKindEnum";

export const processHarmonogram = (stateList) => {
    processStateList(stateList);
    return stateList;
};

const processStateList = (stateList) => {
    processEventList(stateList);
    processDelay(stateList);

    return stateList;
};

const processEventList = (stateList) => {
    stateList.forEach(state => {
        let eventLength = 0;
        state.eventList.forEach(event => {
            let startDate = new LocalDate(event.from);
            let endDate = new LocalDate(event.to);
            let length = endDate.dayDifference(startDate);

            event.realLength = length;
            eventLength += length; 
        });
        state.eventLength = eventLength;
    });
};

const processDelay = (stateList) => {

    stateList.forEach(state => {
        state.delay = {
            client:     [],
            company:    [],
            god:        []
        };
        if (state.kind === stateKindEnum.CLIENT) {
            let delay = state.realLength - state.expectedLength;
            if (delay > 0)
                state.delay.client.push({
                    length: delay,
                    kind: delayKindEnum.CLIENT_DELAY
                });
            if (delay < 0)
                state.delay.client.push({
                    length: delay,
                    kind: delayKindEnum.CLIENT_ADVANCE
                });
            if (state.addToNextState > 0)
                state.delay.client.push({
                    length: state.addToNextState,
                    kind: delayKindEnum.CLIENT_RESTORE
                });
        } else {
            let delay = state.realLength - state.expectedLength - state.restoreTime;
            if (delay < 0)
                state.delay.company.push({
                    length: delay,
                    kind: delayKindEnum.COMPANY_ADVANCE
                });
            if (state.clima === 1 && delay > 0)
                state.delay.god.push({
                    length: delay,
                    kind: delayKindEnum.GOD_CLIMA
                });
            if (state.clima === 0 && delay > 0) {
                if (state.eventLength > 0)
                    state.delay.god.push({
                        length: state.eventLength,
                        kind: delayKindEnum.GOD_EVENT
                    });
                state.delay.company.push({
                    length: delay - state.eventLength,
                    kind: delayKindEnum.COMPANY_DELAY
                });
            }
        }
    });
};
