import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import LoginPage from './features/user/LoginPage';
import PasswordRequestPage from './features/user/PasswordRequestPage';
import Navigation from './features/navigation/Navigation';
import MessageBox from './features/common/MessageBox';
import ActivityIndicator from './features/common/ActivityIndicator';
import {
    commonSelector,
	showScroll,
	hideScroll
} from './features/common/commonSlice';
import {
	userSelector
} from './features/user/userSlice';
import { init } from './features/user/thunks/init';
import { useTranslation } from 'react-i18next';

const App = () => {

	const dispatch = useDispatch();
	const { i18n } = useTranslation();

	const common = useSelector(commonSelector);
	const user = useSelector(userSelector);

	const checkScrollTop = () => {
        if (!common.scroll && window.pageYOffset > 400){
            dispatch(showScroll());
        } else if (common.scroll && window.pageYOffset <= 400){
            dispatch(hideScroll());
        }
    };

	window.addEventListener('scroll', checkScrollTop);

	useEffect(
        () => {
			const setDomainLocalization = () => {
				let re = /\.(cz|sk|pl)$/;
				let match = window.location.host.match(re);
		
				if (match == null)
					return;
		
				i18n.changeLanguage(match[1]);
			};

			const setDocumentLanguage = () => {
				const language = ({
					['cz']: 'cs',
					['sk']: 'sk',
					['pl']: 'pl',
					['en']: 'en'
				})[i18n.language];

				if (language == null)
					return;

				document.documentElement.lang = language;
			};

			setDomainLocalization();
			setDocumentLanguage();
			dispatch(init());
        }, 
        [dispatch, i18n]
    );

	return (
		<div className="App">
			{ common.message ?
				(
					<MessageBox />
				) : 
				common.pending &&
				(
					<ActivityIndicator />
				)
			}
			{ user.passwordRequestKey != null ?
				(
					<PasswordRequestPage />
				) : user.user == null ?
				(
					<LoginPage />
				) :
				(
					<Navigation />
				)
			}
		</div>
	);
};

export default App;
