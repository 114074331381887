import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Loan.module.css';
import LoanLoanList from './LoanLoanList';


const Loan = () => {

    const { t } = useTranslation();

    return (
        <div className={styles.loan}>

            <div className={styles.motivation}>
            <h3>{t("Půjčky klienta")}</h3>
                {t("LOAN_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
            </div>

            <div className={styles.layout}>
                <LoanLoanList />
            </div>

        </div>
    );
}

export default Loan;