import React from 'react';
import styles from './Communication.module.css';
import { messageDirectionEnum } from './enum/messageDirectionEnum';


const CommunicationMessage = ({item}) => {

    const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(new Date(item.date));
    // const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(new Date(item.date));

    if (item.direction === messageDirectionEnum.CLIENT_TO_COMPANY)
        return (
            <li className={[styles.message, styles.client].join(" ")}>
                <p>{item.text}</p>
                <span className={styles.date}>{date} &gt; {item.member.name}</span>
            </li> 
        );
    else
        return (
            <li className={[styles.message, styles.company].join(" ")}>
                <img src={item.member.imageUrl} alt={item.member.name} />
                <p>{item.text}</p>
                <span className={styles.date}>{date} &lt; {item.member.name}</span>
            </li>
        );
};

export default CommunicationMessage;