import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import HarmonogramPage from '../harmonogram/HarmonogramPage';
import HelplinePage from '../helpline/HelplinePage';
import FilePage from '../file/FilePage';
import LoanPage from '../loan/LoanPage';
import ReferencePage from '../reference/ReferencePage';
import ServicePage from '../service/ServicePage';
import StatePage from '../harmonogram/StatePage';
import CommunicationPage from '../communication/CommunicationPage';
import IndexPage from '../index/IndexPage';
import ScrollToTop from './ScrollToTop';


const Navigation = () => {

	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<Redirect from="/access/:key" to="/" />
				<Redirect from="/password/:key" to="/" />
				<Route path="/harmonogram">
					<HarmonogramPage />
				</Route>
				<Route path="/state/:id">
					<StatePage />
				</Route>
				<Route path="/communication">
					<CommunicationPage />
				</Route>
				<Route path="/helpline">
					<HelplinePage />
				</Route>
				<Route path="/file">
					<FilePage />
				</Route>
				<Route path="/loan">
					<LoanPage />
				</Route>
				<Route path="/reference">
					<ReferencePage />
				</Route>
				<Route path="/service">
					<ServicePage />
				</Route>
				<Route path="/">
					<IndexPage />
				</Route>
			</Switch>
		</Router>
	);
}

export default Navigation;