import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LocalDate } from '../../lib/localDate';
import { stateKindEnum } from './enum/stateKindEnum';
import { stateStageEnum } from './enum/stateStageEnum';
import styles from './Harmonogram.module.css';
import {
    harmonogramSelector
} from './harmonogramSlice';


const HarmonogramNoticeList = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    const currentState = harmonogram.harmonogram.find(state => state.stage === stateStageEnum.CURRENT);

    return (
        <div className={styles.harmonogramNoticeList}>
            { currentState.eventList.length > 0 &&
                currentState.eventList[currentState.eventList.length - 1].to === (new LocalDate()).today().getDate() &&
                    <div className={[styles.notice,styles.hurdle].join(' ')}>
                        <h3>{t("Mimořádná událost")}</h3>
                        <p className={styles.text}>{currentState.eventList[currentState.eventList.length - 1].note}</p>
                    </div>
            }
            { currentState.kind === stateKindEnum.CLIENT &&
                <div className={[styles.notice,styles.cooperation].join(' ')}>
                    <h3>{t("Etapa vyžaduje vaší součinnost")}</h3>
                    <p className={styles.text}>{t("Aktuální etapa vyžaduje vaší součinnost. Případné zpoždění není přičítáno dodavateli.")}</p>
                </div>
            }
        </div>
    );
};

export default HarmonogramNoticeList;