import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalDate } from '../../lib/localDate';
import { delayKindEnum } from './enum/delayKindEnum';
import { stateKindEnum } from './enum/stateKindEnum';
import { stateStageEnum } from './enum/stateStageEnum';
import styles from './Harmonogram.module.css';


const StateContent = ({state}) => {

    const { t } = useTranslation();
    
    const stageStyle = ({
        [stateStageEnum.HISTORY]: styles.finished,
        [stateStageEnum.CURRENT]: styles.current,
        [stateStageEnum.FUTURE]:  styles.future
    })[state.stage];
    
    const kindStyle = state.kind === stateKindEnum.COMPANY ? styles.company : styles.client;

    const formatDate = (date) => new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(date)).replace(/ /g, "");

    const endDate = ({
        [stateStageEnum.HISTORY]: (new LocalDate(state.date)).addInterval({day: state.realLength}).getDate(),
        [stateStageEnum.CURRENT]: (new LocalDate(state.date)).addInterval({day: state.expectedLength}).getDate(),
        [stateStageEnum.FUTURE]:  (new LocalDate(state.date)).addInterval({day: state.expectedLength}).getDate()
    })[state.stage];

    const delayTitleMap = {
        [delayKindEnum.CLIENT_DELAY]:       t("Zpoždění na straně klienta"),
        [delayKindEnum.CLIENT_ADVANCE]:     t("Předčasně dokončená etapa klienta"),
        [delayKindEnum.CLIENT_RESTORE]:     t("Doba potřebná pro obnovení výroby"),
        [delayKindEnum.COMPANY_DELAY]:      t("Zpoždění na straně dodavatele"),
        [delayKindEnum.COMPANY_ADVANCE]:    t("Předčasně dokončená etapa dodavatele"),
        [delayKindEnum.GOD_CLIMA]:          t("Zpoždění v klimaticky nevhodných podmínkách"),
        [delayKindEnum.GOD_EVENT]:          t("Mimořádné události")
    };

    return (
        <div className={styles.layout}>
            <h2>{t("Detail")}</h2>

            <h3 className={[styles.name, kindStyle, stageStyle].join(' ')}>{state.title}</h3>

            { state.stage === stateStageEnum.CURRENT && 
                state.eventList.length > 0 &&
                state.eventList[state.eventList.length - 1].to === (new LocalDate()).today().getDate() &&
                    <div className={[styles.notice, styles.hurdle].join(' ')}>
                        <h3>{t("Mimořádná událost")}</h3>
                        <p className={styles.text}>{state.eventList[state.eventList.length - 1].note}</p>
                    </div>
            }

            { state.kind === stateKindEnum.CLIENT && 
                <div className={[styles.notice, styles.cooperation].join(' ')}>
                    <h3>{t("Etapa vyžaduje vaší součinnost")}</h3>
                    <p className={styles.text}>{t("Etapa vyžaduje vaší součinnost. Případné zpoždění není přičítáno dodavateli.")}</p>
                </div>
            }

            <div className={styles.time}>
                <div className={styles.start}>
                    <span className={styles.date}>{formatDate(state.date)}</span>
                    <span className={styles.title}>{t("Začátek etapy")}</span>
                </div>
                <div className={styles.end}>
                    <span className={styles.date}>{formatDate(endDate)}</span>
                    <span className={styles.title}>{t("Konec etapy")}</span>
                </div>
            </div>

            { state.stage === stateStageEnum.HISTORY && state.note !== '' &&
                <div className={styles.log}>
                    <h3>{t("Zápis z etapy")}</h3>
                    <p>{state.note}</p>
                </div>            
            }

            { state.eventList.length > 0 &&
                <div className={styles.hurdleList}>
                    <h3>{t("Mimořádné události")}</h3>
                    <ul>
                        { state.eventList.map(event => (
                            <li key={event.id}>
                                <span className={styles.start}>{formatDate(event.from)}</span>
                                <span className={styles.end}>{formatDate(event.to)}</span>
                                <p>{event.note}</p>
                            </li>
                        ))}
                    </ul>
                </div>            
            }

            { state.stage !== stateStageEnum.FUTURE &&
                <div className={styles.delay}>
                    <h3>{t("Započítané zpoždění")}</h3>
                    { state.delay.client.map((delay, index) => (
                        <div className={styles.client} key={index}>
                            <span className={styles.length}>{['','+'][+(delay.length > 0)] + delay.length}</span>
                            <span className={styles.title}>{delayTitleMap[delay.kind]}</span>
                        </div>    
                    ))}
                    { state.delay.company.map((delay, index) => (
                        <div className={styles.company} key={index}>
                            <span className={styles.length}>{['','+'][+(delay.length > 0)] + delay.length}</span>
                            <span className={styles.title}>{delayTitleMap[delay.kind]}</span>
                        </div>    
                    ))}
                    { state.delay.god.map((delay, index) => (
                        <div className={styles.god} key={index}>
                            <span className={styles.length}>{['','+'][+(delay.length > 0)] + delay.length}</span>
                            <span className={styles.title}>{delayTitleMap[delay.kind]}</span>
                        </div>    
                    ))}
                </div>            
            }

            <div className={styles.description}>
                <h3>{t("Popis")}</h3>
                <p>{state.description}</p>
            </div>
        </div>
    );
}

export default StateContent;