import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './Common.module.css';
import {
    userSelector
} from '../user/userSlice';

const User = () => {

    const { t } = useTranslation();
    const user = useSelector(userSelector);

    return (
        <div className={styles.user}>
            <ul>
                <li className={styles.name}>
                    <span className={styles.item}>{t('Jméno')}</span>
                    <span className={styles.value}>{user.user.firstName} {user.user.lastName}</span></li>
                <li className={styles.number}>
                    <span className={styles.item}>{t('Číslo')}</span>
                    <span className={styles.value}>{user.user.number}</span></li>
                <li className={styles.program}>
                    <span className={styles.item}>{t('Program')}</span>
                    <span className={styles.value}>{user.user.program}</span></li>
                <li className={styles.state}>
                    <span className={styles.item}>{t('Stav')}</span>
                    <span className={styles.value}>{user.user.state}</span></li>
            </ul>
        </div>
    );
};

export default User;